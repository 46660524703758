<template>
<div class="page">
  <navbar :current-user="you.email" :archived="archived" :admin="admin"/>
    <div class="header-container">
  <h1 v-if="names != null" class="title text-center">{{ recipients.map(r => r.email).join(", ") }}</h1>
  </div>
  <div v-if="!loading" class="msg-container">
  <div v-if="messages != null" class="accordion accordion-flush">
  <div v-for="(message, index) in messages" :key="index" class="accordion-item">
    <h2 class="accordion-header" :id="'a-'+index+ '-heading'">
      <button :class="index === messages.length - 1 ? 'accordion-button d-none d-lg-block' : 'accordion-button collapsed d-none d-lg-block'" type="button" data-bs-toggle="collapse" :data-bs-target="'#a-' + index" :aria-expanded="index === messages.length - 1 ? true : false" :aria-controls="'a-'+index">
        <div class="float-start ">
          {{message.Header}}
          <b v-if="message.Subject != ''" class="ms-1">Subject: {{message.Subject}}</b>
        </div>
        <div class="float-end"><small class="text-muted">{{date_format(message.Created)}}</small></div>
      </button>
      <!-- MOBILE -->
      <button :class="index === messages.length - 1 ? 'accordion-button d-lg-none' : 'accordion-button collapsed d-lg-none'" type="button" data-bs-toggle="collapse" :data-bs-target="'#a-' + index" :aria-expanded="index === messages.length - 1 ? true : false" :aria-controls="'a-'+index">
        <div>
          <!-- FIX ME -->
          <div class="mb-1">{{message.Header}}</div>
          <div v-if="message.Subject != ''"><b>Subject: {{message.Subject}}</b></div>
        </div>
        <!-- <div v-else class="float-start ">To: {{ recipients }},
        <div v-if="message.Subject != ''"><b>Subject: {{message.Subject}}</b></div>
        </div> -->
        <div><small class="text-muted">{{date_format(message.Created)}}</small></div>
      </button>
    </h2>
    <div :id="'a-'+index" :class="index === messages.length - 1 ? 'accordion-collapse show' : 'accordion-collapse collapse'" :aria-labelledby="'a-'+index + '-heading'">
      <div class="accordion-body">
        <div v-if="!message.ToNewUser">
          <p>{{ message.Body }}</p>
          <div v-if="message.Files">
            <div class="file-container p-1 my-1 rounded me-2" :key="index" v-for="(file, index) in message.Files">
              <a class="file-link" @click="download(file.ID, file.Name, message.ID)"><file class="file-icon" />{{ file.Name }}</a>
            </div>
          </div>
          <div v-if="(admin && message.Resend)" class="mt-4"><a v-if="messages != null"  class="btn btn-sm btn-primary" @click="resendMessage(message.ID)">Resend</a></div>
        </div>
      </div>
    </div>

  </div>
  </div>
  <div class="mb-5" v-else>
    <h4 class="text-center">No messages</h4>
  </div>
  <!-- <div v-show="!archived" class="text-center my-4"> -->
      <div class="text-center my-4">
    <div v-if="admin">
      <a @click="modal.show()" class="btn btn-lg btn-primary m-2">{{ messages != null ? 'Send Another Message' : 'New Message' }}</a>
      <!-- <a v-if="messages != null"  class="btn btn-lg btn-primary m-2" @click="resendMessage(messages.slice(-1)[0].ID)">Resend This Message</a> -->
    </div>
    <a v-else @click="modal.show()" class="btn btn-lg btn-primary">{{ messages != null ? 'Reply' : 'New Message' }}</a>
  </div>
  </div>
  <div v-else class="msg-container text-center">
    <div class="spinner-border text-primary" role="status">
  <span class="visually-hidden">Loading...</span>
</div>
  </div>
<div class="modal fade"  data-bs-backdrop="static" ref="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">New Message</h5>
          <button type="button" class="btn-close" @click="modal.hide()" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <div >To: {{recipients.map(r => r.email).join(", ")}}</div>
          </div>
          <div class="mb-3">
            <label for="subject">Subject:</label><input @focus="reset" type="text" v-model="subject" class="form-control" />
          </div>
          <div>
            <label for="message">Message:</label><textarea id="message" @focus="reset" v-model="message" class="form-control" rows="10" style="resize:none" />
          </div>
          <div class="my-2">
            <div>
              <input type="file" id="file-upload" ref="file" v-on:change="handleFileUpload()" hidden/><label for="file-upload" class="btn btn-secondary">ATTACH FILES</label></div>
              <div class="my-4">
              <ul class="files"><li class="p-2 mx-1 rounded" v-for="f in files" :key="f">{{f.name}}<a @click="deleteFile(f.name)" class="delete-file mx-2">&#10006;</a></li></ul>
              </div>
              <div v-if="error"><small class="text-danger">{{error_msg}}</small></div>
          </div>
        </div>
        <div v-if="!clicked" class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="modal.hide()">Cancel</button>
          <button type="button" class="btn btn-primary" @click="send">Send</button>
        </div>
        <div v-else class="modal-footer">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div v-else class="onetime-container border p-5">
      <div>
      <h1 class="text-center mb-3">One Time Passcode</h1>
      <div class="mb-3">
          <label for="password" class="form-label">Enter the passcode that was provided in you email.</label>
          <input @focus="reset" type="password" v-model="password" class="form-control" id="password" placeholder="New Password">
      </div>
      <div class="mb-3">
          <label for="password-confirm" class="form-label">Confirm New Password</label>
          <input @focus="reset" type="password" ref="confirmPass" v-model="confirmPass" class="form-control" id="password-confirm" placeholder="Confirm New Password">
      </div>
      <div v-show="error" class="invalid-feedback">
          {{error_msg}}
      </div>
      <div class="text-center">
          <button @click="setPassword" class="btn btn-primary">Change Password</button>
      </div>
    </div>
</div> -->
</template>
<script>
import Decrypt from '@/components/Decrypt.vue'
import Navbar from '@/components/Navbar.vue'
import paperclip from '@/assets/paperclip-solid.svg'
import arrow from '@/assets/arrow-left-solid.svg'
import file from "@/assets/file-download.svg"
import user from '@/assets/user-solid.svg'
import authHeader from '../services/auth-header';
import moment from 'moment'
import { Modal } from 'bootstrap'
export default {
  name: 'Message',
  components: {
    Navbar,
    Decrypt,
    paperclip,
    arrow,
    file,
    user,
  },
    data: function() {
    return {
      msPerDay: 1000 * 60 * 60 * 24,
      message: '',
      subject: '',
      userId: '',
      admin: false,
      messages: null,
      names: null,
      id: this.$route.params.id,
      you: {id: "", email: ""},
      recipients: [],
      // other_id: '',
      file: '',
      files:[],
      modal: null,
      error: false,
      error_msg: "",
      archived: false,
      apiUrl: "",
      loading: false,
      clicked: false,
      one_time: false,
      pass: "",
      decrypt_error: false,
      decrypt_msg: "",
      resend: false,
      downloadAsAdmin: false,
      toArchived: false
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created() {
    if (!this.loggedIn) {
      this.$router.push('/login');
    }
  },
  mounted() {
        this.modal = new Modal(this.$refs.modal);
    const el = this.$refs.scroll
    var self = this;
    this.loading = true
    var getUrl = ""
    var readUrl = ""
    axios.get("/api/users/current", {withCredentials: true, headers: authHeader()}).then((res) => {
      this.userId = res.data.id
      this.admin = res.data.admin
      if(this.admin) {
        self.apiUrl = "/api/admin/" + this.userId + "/messages/"
        getUrl = "/api/admin/" + this.userId + "/message_info/"
      }
      else {
        self.apiUrl = "/api/user/" + this.userId + "/messages/"
        getUrl = "/api/user/" + this.userId + "/message_info/"
      }
    }).then(() => {
      axios.get(getUrl + this.id , {withCredentials: true, headers: authHeader()})
      .then((response) => {
        self.archived = response.data.archived
        console.log(self.archived)
        self.names = response.data
        //if admin
        if(self.userId === response.data.admin.id) {
          self.downloadAsAdmin = true
          self.you = response.data.admin
          self.recipients = response.data.users
          // self.other_id = response.data.user_id

        }
        else { //else loop through users
        var users = response.data.users
        users.push(response.data.admin)
        for(var i = 0; i < users.length; i++) {
          if(self.userId === users[i].id) {
            self.you = users[i]
            users.splice(i, 1);
          }
        }
          self.recipients = response.data.users
        }

      }).then((res) => {
      axios.get(self.apiUrl + this.id, {withCredentials: true, headers: authHeader()})
      .then((response) => {
        self.messages = response.data.Messages;
        self.resend = response.data.Resend;
        console.log(self.messages)
        if(self.messages != null) {
          for(const msg of self.messages) {
            if(msg.ToNewUser) {
              if(!self.admin) {
              var header = authHeader()
              header['Access-Control-Allow-Origin'] = process.env.VUE_APP_API_BASE_URL
              header["Content-Type"] = "application/json"
              axios.post("/api/decrypt", {MessageId: msg.ID, UserId: self.userId, Pass: self.you.email}, {withCredentials: true, headers: header}).then((res) => {
                this.$router.go()
              })
              }
              this.one_time = true;
            }
          }
        } else {
          if(self.admin) {
            if(!self.archived) {
              self.modal.show()
            }
          }
        }
        // header['Access-Control-Allow-Origin'] = process.env.VUE_APP_API_BASE_URL
        // header["Content-Type"] = "application/json"
        var header = authHeader()
        header['Access-Control-Allow-Origin'] = process.env.VUE_APP_API_BASE_URL
        header["Content-Type"] = "application/json"
        axios.post("/api/reads", {You: self.userId, Admin: self.admin, id: self.id}, {withCredentials: true, headers: header})
        
        this.loading = false
      })
      .catch((err) => {
        this.loading = false
      })
      })
    })
  },
  methods: {
    reset() {
      this.error = false;
      this.error_msg = "";

    },
    dateCheck: function (date) {
      var mDate = new Date(date)
      var today = new Date()

      if(this.dateDiffInDays(mDate, today) > 10) {
        return true;
      }
      else {
        return false;
      }


    },
    date_format: function (date) {
      new Date(date)
      return moment.utc(date).format('MMMM DD, YYYY [at] h:mm A');
    },
    dateDiffInDays(a, b) {
      // Discard the time and time-zone information.
      const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
      const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

      return Math.floor((utc2 - utc1) / this.msPerDay);
    },
    expireOutput(a) {
      a = new Date(a)
      var days = this.dateDiffInDays(new Date(), a)
      var s = (days != 1)
      if(days >= 0)
        return `Expires in ${days} day${s ? 's' : ''}`
      else
        return "Expired"
    },
    download: function(id, name, message_id) {
      var header = authHeader()
      header['Access-Control-Allow-Origin'] = process.env.VUE_APP_API_BASE_URL
      header["Content-Type"] = 'application/json'
      axios.post("/api/file", {file_id: id, user_id: this.userId, message_id: message_id, admin: this.downloadAsAdmin }, {withCredentials: true, responseType: 'blob', headers: header})
      .then(function (response) {
        console.log(response)
      var headers = response.headers;
      var blob = new Blob([response.data],{type:headers['content-type']});
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = name
        link.click()
      });
      
    },
    handleFileUpload: function() {
      var sameName = false;

      if(this.files.length > 0) {
        for(var i = 0; i < this.files.length; i++) {
          if(this.files[i].name === this.$refs.file.files[0].name) {
            sameName = true;
            break;
          }
        }
      }

      if(sameName) {
        alert("You have already added a file with the same name. Please rename the file")
      }
      else {
        this.files.push(this.$refs.file.files[0])
        this.file = this.$refs.file.files[0]
      }


    },
    deleteFile: function(name) {
      for(var i = 0; i < this.files.length; i++) {
        if(this.files[i].name == name) {
          this.files.splice(i, 1);
          break;
        }
      }
      
    },
    send: function() {
      this.clicked = true;
      var header = authHeader()
      header['Access-Control-Allow-Origin'] = process.env.VUE_APP_API_BASE_URL
      header["Content-Type"] = 'multipart/form-data'
      var self = this;
      var to = ""

      let formData = new FormData();
      formData.append("messageInfoId", this.id)
      formData.append("senderId", this.userId)
      formData.append("body", this.message)
      formData.append("subject", this.subject)
      // formData.append("archived", this.archived)
      formData.append("admin", this.admin)

      if(this.message == '') {
        self.clicked = false
        this.error = true
        this.error_msg = "The message body cannot be blank"
      }
      else if(this.subject == '') {
        self.clicked = false
        this.error = true
        this.error_msg = "The message subject cannot be blank"
      }
      else {
      for( var i = 0; i < this.files.length; i++ ){
          let file = this.files[i];
          formData.append('files[]', file);

      }
      axios.post("/api/messages", formData, {withCredentials: true, headers: header})
      .then((res) => {
        self.clicked = false
        self.modal.hide()

        axios.post("/api/read", {MessageId: res.data.ID, You: self.userId}, {withCredentials: true, headers: header})
        axios.get(self.apiUrl + self.id, {withCredentials: true, headers: authHeader()})
        .then((response) => {
          self.message = '';
          self.subject = '';
          self.resend = response.data.Resend
          if(self.messages != null) {
          self.messages.splice(0, self.messages.length)
          self.messages.push(...response.data.Messages)
          self.files.splice(0, self.files.length)
          }
          else {
            self.messages = response.data.Messages
          }
        })
      })
      .catch((err) => {
        self.clicked = false;
          self.error = true
          self.error_msg = err.response.data.error
          
        })
      }
    },
    resendMessage(messageId) {
      var self = this
      var header = authHeader()
      header['Access-Control-Allow-Origin'] = process.env.VUE_APP_API_BASE_URL
      header["Content-Type"] = "application/json"
      axios.post("/api/resend", {MessageId: messageId}, {withCredentials: true, headers: header}).then((res) => {
        if(self.archived) {
          self.$router.push("/message/" + res.data.MessageInfoId);
          this.$router.go()
          // self.$router.push("/message/" + res.data)
        }
        else {
          this.$router.go()
        }
      })
      .catch((err) => {
        this.decrypt_error = true
        this.decrypt_msg = err.response.data.error
      })
    },
    decryptOneTime(messageId) {
      this.decrypt_error = false
      this.decrypt_msg = ""
      if(this.pass != "") {
      var header = authHeader()
      header['Access-Control-Allow-Origin'] = process.env.VUE_APP_API_BASE_URL
      header["Content-Type"] = "application/json"
      axios.post("/api/decrypt", {MessageId: messageId, UserId: this.userId, Pass: this.pass}, {withCredentials: true, headers: header}).then((res) => {
        this.$router.go()
      })
      .catch((err) => {
        this.decrypt_error = true
        this.decrypt_msg = err.response.data.error
      })
      }
      else {

        this.decrypt_error = true;
        this.decrypt_msg = "No passcode entered"

      }

    }

  }
}
</script>
<style scoped>
.page {
  height: 100%;
  overflow-x: hidden;
}

.arrow {
  color: #b8bbcc;
  height: 35px;
  width: 35px;

}

.header-container {
    display:flex;
  flex-wrap:nowrap;
  align-items:center
}

.header-container h1 {
  flex-grow:1;       /* take up rest of line */
  text-align:center;
}

.msg-container {
  margin: 3rem 0;
}

.accordion-button {
  background-color: #f0f2f5;
  display: block
}
.accordion-button:not(.collapsed) {
  color: #000;
   background-color: #fff;
  
}

.avatar {
  color: white;
  font-size: 24px;
  text-align: center;
  vertical-align: middle;
  display:inline-block; 
  width: 40px; 
  height: 40px;
  /* width: 100%;
  height: 100%; */
  line-height: 40px;
}

.avatar-admin {
  color: white;
  font-size: 24px;
  text-align: center;
  vertical-align: middle;
  display:inline-block; 
  width: 50px; 
  height: 40px;
  /* width: 100%;
  height: 100%; */
  line-height: 40px;
}

.avatar-sender {
  background-color: #1375d6;
}

.avatar-recipient {
  background-color: #d66e13;
}

.files {
  list-style: none;
margin: 0;
padding: 0;
}

.files li {
  background-color: #4291eb;
  color: #fff;
  display: inline;
}

.delete-file {
  cursor: pointer;
  text-decoration: none;
}

.file-link {
  text-decoration: none;
      cursor: pointer;
  color: #000;
}

.file-container {
  display: inline-block;
      background-color: #d4d8df;
}


.file-icon {
  height: 20px;
  width: 20px;
  color: #000;
  margin-right: 5px;
}

.accordion {
  border: 1px solid black;
}

.accordion-item {
  border: 1px solid black;
}

.accordion-body {
    /* padding-bottom: 5rem; */
}
.onetime-container {
    min-width: 30rem;
    margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.back {
  text-decoration: none;
}
.back-text {
   color: black;
}
</style>