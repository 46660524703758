<template>
    <div>
    <label class="mb-3">Enter your email address to view this meesage. You will only need to do this once.</label>
    <div class="input-group mb-3" style="max-width: 40rem">
    <input type="text" v-model="pass" class="form-control" placeholder="Passcode"/>
        <a class="btn btn-primary" @click="decryptOneTime()">Submit</a>
    </div>
    <div v-if="decrypt_error" class="text-danger">{{decrypt_msg}}</div>
    </div>
</template>
<script>
import authHeader from '../services/auth-header';
export default {
    name: 'decrypt',
    props: {
        messageId: String,
        userId: String
    },
    data: function() {
        return {
            pass: "",
            decrypt_error: false,
            decrypt_msg: ""

        }
    },
    methods: {
        decryptOneTime() {
        this.decrypt_error = false
        this.decrypt_msg = ""
        if(this.pass != "") {
        var header = authHeader()
        header['Access-Control-Allow-Origin'] = process.env.VUE_APP_API_BASE_URL
        header["Content-Type"] = "application/json"
        axios.post("/api/decrypt", {MessageId: this.messageId, UserId: this.userId, Pass: this.pass}, {withCredentials: true, headers: header}).then((res) => {
            this.$router.go()
        })
        .catch((err) => {
            this.decrypt_error = true
            this.decrypt_msg = err.response.data.error
        })
        }
        else {

            this.decrypt_error = true;
            this.decrypt_msg = "No passcode entered"

        }

        }
    }
    
}
</script>
<style scoped>
</style>