import Vue from 'vue'
import App from './App.vue'
import vSelect from "vue-select"
import router from './router'
import store from './store'
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
// import 'jquery/src/jquery.js'
// import 'bootstrap/dist/js/bootstrap.min.js'
import './style/style.css'
import "vue-select/dist/vue-select.css";
import moment from 'moment'

window.axios = require('axios')
// window.axios.defaults.headers.common['X-Csrf-Token'] = window.csrfTokenValue;
// window.axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
// window.axios.defaults.headers.common['Access-Control-Allow-Origin'] = "http://localhost:8080"
window.axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL

// window.axios.interceptors.response.use(response => {
//   return response;
// }, error => {
//   if (error.response.status === 401) {
//   //place your reentry code
//   store.dispatch('auth/logout').then(
//     () => {
//     router.push("/login")
//     }
// )
//   }
//   return error;
// });

window.axios.interceptors.request.use(function (config) {
 
  config.metadata = { startTime: new Date()}
  return config;
}, function (error) {
  return Promise.reject(error);
});


window.axios.interceptors.response.use(response => {
  //if response duration takes too long error out
  // if(moment().subtract(response.config.metadata.startTime,new Date()).milliseconds > 10000) {
  //   console.log("error alskjdlaksjdklsa")
  // }
  if (response.status === 401) {
    //place your reentry code
    store.dispatch('auth/logout').then(
      () => {
      router.push("/login")
      }
  )
    }
  return response;
}, error => {

  // if(error.response == undefined) {
  //   router.push("/maintenance")
  // }

  // if(moment.subtract(response.config.metadata.endTime, new Date()).milliseconds > 10000) {
  //   console.log("error alskjdlaksjdklsa")
  // }

  if (error.response.status === 401) {
    //place your reentry code
    store.dispatch('auth/logout').then(
      () => {
      router.push("/login")
      }
  )
    }

  return Promise.reject(error);
});

Vue.config.productionTip = false

Vue.component("v-select", vSelect);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
