<template>
<div style="overflow-x:hidden">
  <div class="d-none d-lg-block">
    <div class="nb mb-2">
      <div>
          <router-link class="back" :to="archived ? '/archived' : '/'">
            <arrow class="arrow" /><span class="back-text ms-2">{{archived ? "Back to Archived" : "Back to Inbox"}}</span>
          </router-link>
      </div>
      <div class="nb-center">
          <router-link class="text-end" to="/"><img class="nav-img" src="@/assets/PHPA_PRIMARY.png" /></router-link>
          <div><a class="phone" href="tel:800-565-0716">800-565-0716</a></div>
      </div>
      <div class="nb-right" style="display: inline-block; vertical-align: middle">
        Logged in as <strong>{{currentUser}}</strong>
        <a @click="logout">Logout</a>
      </div>
    </div>
  </div>
    <div class="d-lg-none row pt-2">
      <div class="col-3">
          <a class="back" :href="archived ? '/archived' : '/'"><arrow class="arrow m-2" /></a>
      </div>
      <div class="col-6">
          <div class="text-center"><router-link to="/"><img style="width: 100%; max-width: 12rem;" src="@/assets/PHPA_PRIMARY.png" /></router-link></div>
          <div class="text-center"><a class="phone" href="tel:800-565-0716">800-565-0716</a></div>
      </div>
      <div class="col-3 text-end">
        <a data-bs-toggle="collapse"
          data-bs-target="#mobileNav"
          aria-controls="mobileNav"
          aria-expanded="false"
          aria-label="Toggle navigation"><mobile class="menu m-2" /></a>
      </div>
    </div>
        <div class="collapse collaps-horizontal" id="mobileNav">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0 text-center">
            <li class="nav-item m-2 text-center">Logged in as <strong>{{ currentUser }}</strong></li>
      <li class="nav-item m-2 text-right"><router-link  class="mobile-link" style="text-decoration: none" to="/change-password">Change Password</router-link></li>
      <li class="nav-item m-2 text-right"><a class="mobile-link" @click="logout">Logout</a></li>
      </ul>
    </div>
</div>
</template>
<script>
import arrow from '@/assets/arrow-left-solid.svg'
import mobile from '@/assets/bars-solid.svg'
export default {
    name: 'navbar',
      components: {
    arrow,
    mobile,
  },
    props: ["currentUser", "archived", "admin"],
    methods: {
        logout: function() {
            this.$store.dispatch('auth/logout')
            .then(() => {
            this.$router.push("/login")
            })
        },
    }
    
}
</script>
<style scoped>
.nb {
  /* display: flex;
  align-items: center; */
  padding: 1rem;
    display: flex;                  /* establish flex container */
  flex-direction: row;            /* default value; can be omitted */
  flex-wrap: nowrap;              /* default value; can be omitted */
  justify-content: space-between; /* switched from default (flex-start, see below) */
}

.nb > div {
  width: 100%;
}

.nb-right {
  /* width: 33%;
  float: right; */
  text-align: right;
  vertical-align: middle;
}

.nb-right a,
.mobile-link {
  font-weight: 600;
  cursor: pointer;
  color: #3660d1 !important;
  margin: 1rem;
  text-decoration: none;
}

.nb-right a:hover {
  color: #618cff !important
}


.nav-img {
  max-width: 14rem;
}
.arrow {
  color: #797c88;
  height: 35px;
  width: 35px;

}
.back {
  text-decoration: none;
}
.back-text {
   color: black;
}
.nb-center {
  text-align: center;
}
.phone {
    text-decoration: none;
  color: #867a50 !important;
  margin: 1rem;
}

.phone:hover {
  color: #C1B072 !important
}
.menu {
    color: #797c88;
  height: 40px;
  width: 40px;
}
</style>
