<template>
  <div>
    <navbar :current-user="user_name" :archived="false"/>
    <div>
    <h1 class="text-center">Archived Messages</h1>
    <div v-if="delete_err" class="text-center text-danger">Unable to delete message</div>
    </div>
    <div v-if="!loading && !load_err" class="container-fluid p-4">
      <div v-if="messages != null">
      <div v-for="message in sortedMessages" :key="message.id" class="card my-2">
        <div class="card-body py-2">
          <div class="card-title m-0">
            <div class="row">
              <div class="col-9">
                <h6><router-link :to="'/message/' + message.id" class="text-reset text-decoration-none stretched-link">{{message.emails.join(", ")}}</router-link></h6>
                <div v-if="message.message != ''">
                  <!-- <span v-if="message.unread > 0">Subject: {{truncateString(message.message, 150)}}</span> -->
                  <span>Subject: {{truncateString(message.message, 150)}}</span>
                  <div><small class="text-muted">{{date_format(message.latest_date)}}</small></div>
                  <div class="d-lg-none"><small>Archived on {{ date_format(message.updated)}}</small></div>
                </div>
                <div v-else>
                  No messages
                </div>
              </div>
              <div class="col-3 text-end">
                <p class="text-end mb-0"><em>{{message.total}} message{{message.total == 1 ? '' : "s"}}</em></p>
                <div class="text-end d-none d-lg-block"><small>Archived on {{ date_format(message.updated)}}</small></div>
                <a @click="deleteMessage(message.id)" class="btn btn-sm btn-primary mt-2" style="z-index: 2; position: relative"><small>Permanently Delete</small></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div v-else>
        You have no messages
      </div>
    </div>
    <div v-else-if="loading && !load_err" class="text-center container-fluid p-4">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div v-else class="text-center container-fluid p-4">
      There was an error while trying to get messages.
    </div>
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import authHeader from '../services/auth-header';
import arrow from '@/assets/arrow-left-solid.svg'
import moment from 'moment'
export default {
  name: 'Archived',
    components: {
    arrow,
    Navbar
  },
  data: function() {
    return {
      userId: '',
      admin: false,
      messages: null,
      users: [],
      email: "",
      selected: "",
      createUserError: "",
      user_name: "",
      loading: false,
      load_err: false,
      delete_err: false,
      OpenIndicator: {
      render: createElement => createElement('span'),
    },
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    sortedMessages: function() {
    return this.messages.sort(function(a,b){return b.updated.getTime() - a.updated.getTime()});
    },
  },
  created() {
    if (!this.loggedIn) {
      this.$router.push('/login');
    }
    else {
    var self = this;
        this.loading = true
    this.load_err = false;
    axios.get("/api/users/current", {withCredentials: true, headers: authHeader()}).then((res) => {
      this.userId = res.data.id
      this.user_name = res.data.name
      this.admin = res.data.admin
      if (res.data.new) {
        this.$router.push('/login');
      }
    }).then(() => {
      if(this.admin) {
        axios.get("/api/message_info/archive/" + this.userId , {withCredentials: true, headers: authHeader()})
        .then((response) => {
          self.messages = response.data
          for(var i = 0; i < self.messages.length; i++)
          {
            self.messages[i].latest_date = new Date(self.messages[i].latest_date)
            self.messages[i].updated = new Date(self.messages[i].updated)
            
          }
        })
      }
      else {
        this.$router.push('/');
      }
    this.loading = false
    })
    .catch((error) => {
      self.load_err = true
    })

    }
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
      date_format: function (date) {
        return moment.utc(date).format('MMMM DD, YYYY [at] h:mm A');
      },
    truncateString(str, n) {
      if (str.length > n) {
        return str.substring(0, n) + "...";
      } else {
        return str;
      }
    },
    deleteMessage(id) {
      var header = authHeader()
      header['Access-Control-Allow-Origin'] = process.env.VUE_APP_API_BASE_URL
      header["Content-Type"] = "application/json"
      if (confirm("Are you sure you want to delete this message?\nYou will not be able to recover it.")) {
        axios.delete("/api/message_info", {headers: header, data: {id: id, admin_id: this.userId}, withCredentials: true}).then((res) => {
          this.$router.go()
        }).catch((error) => {
          this.delete_err = true
        })
      }

    }
  }
}
</script>
<style scoped>

.nb {
  display: flex;
  align-items: center;
  padding: 1rem;
}

.nb-right {
  width: 50%;
  float: right;
  text-align: right;
}

.nb a {
  font-weight: 600;
  cursor: pointer;
  color: #3660d1 !important;
  margin: 1rem;
}

.nb a:hover {
  color: #618cff !important
}

.unread {
  background-color: #bfdeff;
}

.nav-img {
  max-width: 10rem;
}

.arrow {
  color: #b8bbcc;
  height: 35px;
  width: 35px;

}

</style>
