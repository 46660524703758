import axios from 'axios';

const API_URL = process.env.VUE_APP_API_BASE_URL + "/api/"

// window.axios.defaults.headers.common['X-Csrf-Token'] = window.csrfTokenValue;
axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = "http://localhost:8080"

class AuthService {
  login(user) {
    var fd = new FormData();
    fd.append('username', user.username);
    fd.append('password', user.password);
    return axios
      .post(API_URL + 'login', fd, {withCredentials: true})
      .then(response => {
        if (response.data.token) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }

        return response.data;
      })
      .catch(err => {
        console.log(err.response)
        return {error: true, error_message: err.response.data.error};
      });
  }

  logout() {
    localStorage.removeItem('user');
  }

  register(user) {
    return axios.post(API_URL + 'signup', {
      username: user.username,
      email: user.email,
      password: user.password
    });
  }
}

export default new AuthService();