<template>
<div>
        <navbar :current-user="user_name" :archived="false"/>
   <div class="password-container p-5">
    <div>
      <h1 class="text-center mb-3">Change Password</h1>
            <div class="mb-4">
          <label for="password" class="form-label">Old Password</label>
          <input @focus="reset" type="password" v-model="oldPass" class="form-control" id="password" placeholder="Old Password">
      </div>
      <div class="mb-4">
          <label for="password" class="form-label">New Password</label>
          <input @focus="reset" type="password" v-model="password" class="form-control" id="password" placeholder="New Password">
      </div>
      <div class="mb-4">
          <label for="password-confirm" class="form-label">Confirm New Password</label>
          <input @focus="reset" type="password" ref="confirmPass" v-model="confirmPass" class="form-control" id="password-confirm" placeholder="Confirm New Password">
      </div>
      <div class="card mb-4">
        <div class="card-body">
        Your password must contain:
        <ul>
        <li>At least 8 characters</li>
        <li>At least one uppercase letter (A-Z)</li>
        <li>At least one lowercase letter (a-z)</li>
        <li>At least one digit (0-9)</li>
        <li>At least one special character</li>
        </ul>
        </div>
      </div>
      <div class="text-center">
          <button v-if="!clicked" @click="setPassword" class="btn btn-primary">Change Password</button>
          <div v-else>
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        <div v-if="error" class="text-danger text-center text-wrap mt-2" style="max-width: 544px">{{error_message}}</div>
        <div v-if="success" class="mt-2">Your password has been updated</div>
      </div>
    </div>
    <!-- <div v-else class="text-center">
      <h3>Success!</h3>
      <div class="my-2">Your password has been set</div>
      <router-link to="/login" class="btn btn-primary">Login</router-link>
    </div> -->
  </div>
  </div>
</template>

<script>
import authHeader from '../services/auth-header';
import Navbar from '@/components/Navbar.vue'
export default {
  name: 'ChangePassword',
    components: {
    Navbar
  },
  data: function() {
    return {
      userId: '',
      admin: false,
      password: "",
      confirmPass: "",
      oldPass: "",
      error: false,
      error_message: "",
      success: false,
      new_user: false,
      clicked: false,
      user_name: "",
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created(){
    if (!this.loggedIn) {
      this.$router.push('/');
    }
    else {
    var self = this;
    this.loading = true
    this.load_err = false;
    axios.get("/api/users/current", {withCredentials: true, headers: authHeader()}).then((res) => {
      this.userId = res.data.id
      this.user_name = res.data.name
      this.admin = res.data.admin
      if (res.data.new) {
        this.$router.push('/login');
      }
    })
    }
  },
  mounted() {
  },
  methods: {
    setPassword: function() {
      this.success = false
        if(this.password == "") {
            this.error = true;
            this.error_message = "Please enter a password"

        }
        if(this.oldPass == "") {
            this.error = true;
            this.error_message = "Please enter your old password"

        }
        else if(this.password != this.confirmPass) {
            this.$refs.confirmPass.classList.add("is-invalid");
            this.error = true;
            this.error_message = "Password does not match"
        }
        else if(this.password.length < 8) {
            this.error = true;
            this.error_message = "Password must be at least 8 characters"
        }
        //one lower, one upper, one digit, one special
        else if(!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(this.password)) {
            this.error = true;
            this.error_message = "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character"
        }
        else {
          this.clicked = true
            var header = authHeader()
            header['Access-Control-Allow-Origin'] = process.env.VUE_APP_API_BASE_URL
            header["Content-Type"] = "application/json"
        var self = this;
          axios.post("/api/change-password", {old_password: this.oldPass, password: this.password, user_id: this.userId}, {withCredentials: true, headers: header})
          .then((res) => {
            self.success = true
            this.clicked = false
            this.oldPass = ""
            this.password = ""
            this.confirmPass = ""

          })
          .catch((err) => {
          this.clicked = false
          this.error = true;
          this.error_message = "Error changing password. Please try again later."
          })
        }
    },
    reset() {
        this.$refs.confirmPass.classList.remove("is-invalid");
        this.error = false;
        this.success = false;
    },
  }
}
</script>
<style scoped>

.password-container {
    min-width: 20rem;
    width: 100%;
    max-width: 35rem;
    margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>
